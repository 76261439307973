"use client";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { INLINES } from "@contentful/rich-text-types";
import {
  TypeFaq,
  TypeQuestionAndAnswer,
  isTypeQuestionAndAnswer,
} from "@contentful/types";
import { isEntry } from "@contentful/utils";
import { faPhone } from "@fortawesome/pro-light-svg-icons/faPhone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Accordion from "@radix-ui/react-accordion";
import { EnvelopeClosedIcon } from "@radix-ui/react-icons";
import { ChevronDownIcon } from "@radix-ui/themes";
import { analytics } from "@yardzen/next-segment";
import { ClickedEvent } from "@yardzen/segmentv2";
import { Link, SegmentClickTypes } from "@yardzen/ui";
import classNames from "classnames";
import React, { ReactNode } from "react";

export const FAQ = ({
  faq,
}: {
  faq: TypeFaq<"WITHOUT_UNRESOLVABLE_LINKS", string>;
}) => {
  const questionsAndAnswers = faq.fields.questionsAndAnswers?.reduce<
    TypeQuestionAndAnswer<"WITHOUT_UNRESOLVABLE_LINKS", string>[]
  >((acc, question) => {
    if (!isEntry(question) || !isTypeQuestionAndAnswer(question)) {
      return acc;
    }
    return [...acc, question];
  }, []);

  const flowName = "FAQ " + faq.fields.title;

  return (
    <div className="mx-auto grid max-w-screen-lg gap-5 pb-12 md:grid-cols-2">
      <div className="space-y-5 pr-2">
        <h2 className="font-display text-[45px]">{faq.fields.title}</h2>
        <p className="text-lg">{faq.fields.description}</p>
        <div className="mt-6 flex items-center gap-2">
          <EnvelopeClosedIcon />
          <Link
            tracking={{
              button_name: "FAQ Mailto Button",
              click_type: SegmentClickTypes.HYPERLINK,
              flow_name: flowName,
            }}
            href="mailto:hello@yardzen.com"
          >
            hello@yardzen.com
          </Link>
        </div>
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faPhone} />
          <Link
            tracking={{
              button_name: "FAQ Phone Button",
              click_type: SegmentClickTypes.HYPERLINK,
              button_content: "(888) 927-3936",
              flow_name: flowName,
            }}
            href="tel:+18889273936"
          >
            (888) 927-3936
          </Link>
        </div>
      </div>
      <Accordion.Root className="border-black-elderberry" type="multiple">
        {questionsAndAnswers?.map((questionsAndAnswer) => (
          <AccordionItem
            key={questionsAndAnswer.sys.id}
            value={questionsAndAnswer.sys.id}
          >
            <AccordionTrigger
              tracking={{
                button_name: "FAQ Question",
                click_type: SegmentClickTypes.BUTTON,
                button_content: questionsAndAnswer.fields.question,
                flow_name: flowName,
              }}
            >
              {questionsAndAnswer.fields.question}
            </AccordionTrigger>
            <AccordionContent value={questionsAndAnswer.sys.id}>
              {questionsAndAnswer.fields.answer &&
                documentToReactComponents(questionsAndAnswer.fields.answer, {
                  // replace links
                  renderNode: {
                    hyperlink: (node) => {
                      if (node.nodeType !== INLINES.HYPERLINK) {
                        return;
                      }
                      const content = node.content[0];
                      if (content.nodeType !== "text") {
                        return;
                      }
                      return (
                        <Link
                          tracking={{
                            button_name: "FAQ Answer Link",
                            click_type: SegmentClickTypes.HYPERLINK,
                            button_content: content.value,
                            flow_name: flowName,
                          }}
                          href={node.data.uri}
                          className="underline"
                        >
                          {content.value}
                        </Link>
                      );
                    },
                  },
                })}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion.Root>
    </div>
  );
};

const AccordionItem = React.forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    className?: string;
    value: string;
  }
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Item
    className={classNames(
      "border-black-elderberry ring-douglas-fir overflow-hidden border-b transition-all first:border-t focus-within:relative focus-within:z-10",
      className,
    )}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Accordion.Item>
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  HTMLButtonElement,
  {
    children: ReactNode;
    className?: string;
    tracking: ClickedEvent;
  }
>(({ children, className, tracking, ...props }, forwardedRef) => (
  <Accordion.Header className="border-black-elderberry flex">
    <Accordion.Trigger
      onClick={async () => {
        await analytics.trackClicked(tracking);
      }}
      className={classNames(
        "font-display hover:bg-douglas-fir/5 focus:bg-douglas-fir/5 group flex h-24 flex-1 cursor-default items-center justify-between rounded-l px-5 text-left text-[15px] font-bold leading-none outline-none transition-all",
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDownIcon
        className="transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180"
        aria-hidden
      />
    </Accordion.Trigger>
  </Accordion.Header>
));
AccordionTrigger.displayName = "AccordionTrigger";

const AccordionContent = React.forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    className?: string;
    value: string;
  }
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={classNames(
      "data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden text-[15px]",
      className,
    )}
    {...props}
    ref={forwardedRef}
  >
    <div className="px-5 py-[15px]">{children}</div>
  </Accordion.Content>
));
AccordionContent.displayName = "AccordionContent";
