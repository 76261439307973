"use client";

import { TypeHero, TypeHeroFields, TypeImageSkeleton } from "@contentful/types";
import { SegmentFlows } from "@yardzen/next-client-util";
import { Button, cn, H3, H4, Link, P } from "@yardzen/ui";
import { cva } from "class-variance-authority";
import { Entry, EntryFieldTypes } from "contentful";
import Image from "next/image";
import ReactMarkdown from "react-markdown";
import { ListItemCollection } from "./ListItemCollection";

type ExtractSymbol<T> = T extends EntryFieldTypes.Symbol<infer U> ? U : never;

export function HeroShowcase({
  hero,
  segmentFlow,
  isDesignProfileQuizEnabled,
}: {
  hero: TypeHero<"WITHOUT_UNRESOLVABLE_LINKS">;
  segmentFlow: SegmentFlows;
  isDesignProfileQuizEnabled?: boolean;
}) {
  const variant = hero?.fields.variant;
  const background = hero.fields.background || "none";
  const textPosition =
    variant === "hero-left-split" || variant === "hero-right-split"
      ? "left"
      : "center";

  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center px-1 max-md:pb-4 md:px-8",
      )}
    >
      <div
        className={cn(
          "w-full max-w-7xl rounded-lg bg-opacity-50 max-md:mt-4 max-md:max-w-full md:mt-4",
          heroVariants({ background }),
        )}
      >
        <div
          className={cn(
            "grid grid-cols-1 justify-center",
            heroVariants({ variant }),
          )}
        >
          <TextCard
            fields={hero.fields}
            variant={variant}
            textPosition={textPosition}
            segmentFlow={segmentFlow}
            isDesignProfileQuizEnabled={isDesignProfileQuizEnabled}
          />
          {hero?.fields.images?.at(0)?.fields.link ? (
            <Link href={hero?.fields.images?.at(0)?.fields.link}>
              <ImageCard
                image={hero?.fields.images?.at(0)}
                mobileImage={hero?.fields.mobileOnlyImages?.at(0)}
                variant={variant}
              />
            </Link>
          ) : (
            <ImageCard
              image={hero?.fields.images?.at(0)}
              mobileImage={hero?.fields.mobileOnlyImages?.at(0)}
              variant={variant}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const heroVariants = cva("", {
  variants: {
    variant: {
      background: "grid-cols-1 gap-8",
      "showcase-large": "grid-cols-1 gap-8",
      "showcase-medium": "grid-cols-1 gap-8 max-md:px-4",
      slider: "grid-cols-1 gap-8",
      "hero-left-split": "md:grid-cols-2 gap-8 md:gap-28 max-md:px-4",
      "hero-right-split": "md:grid-cols-2 gap-8 md:gap-28 max-md:px-4",
      values: "grid-cols-1 gap-8",
      grid: "grid-cols-1 gap-8",
      about: "grid-cols-1 gap-8",
    },
    background: {
      "moss-green":
        "py-8 md:px-8 bg-brand-moss-greens-10 max-md:mt-12 md:mt-12",
      none: "py-4 md:py-8",
    },
  },
});

const textVariants = cva("", {
  variants: {
    variant: {
      background: "text-brand-secondary-dark text-4xl",
      "showcase-large": "text-brand-secondary-dark text-6xl max-md:px-4",
      "showcase-medium":
        "text-brand-secondary-dark text-3xl lg:text-4xl text-center",
      slider: "text-brand-secondary-dark text-4xl",
      "hero-left-split":
        "text-brand-secondary-dark text-3xl md:text-4xl max-md:text-center",
      "hero-right-split":
        "text-brand-secondary-dark text-3xl md:text-4xl max-md:text-center",
      values: "text-brand-secondary-dark text-4xl",
      grid: "text-brand-secondary-dark text-4xl",
      about: "text-brand-secondary-dark text-4xl",
    },
    descriptionVariant: {
      background: "text-brand-primary-regular text-base",
      "showcase-large": "text-brand-primary-regular text-base max-md:px-4",
      "showcase-medium": "text-brand-primary-regular text-base",
      slider: "text-brand-primary-regular text-base",
      "hero-left-split":
        "text-brand-moss-greens-90 text-base md:text-lg text-center md:text-left",
      "hero-right-split":
        "text-brand-moss-greens-90 text-base md:text-lg text-center md:text-left",
      values: "text-brand-secondary-dark text-4xl",
      grid: "text-brand-secondary-dark text-4xl",
      about: "text-brand-secondary-dark text-4xl",
    },
    background: {
      "moss-green": "",
      none: "",
    },
  },
  compoundVariants: [
    {
      variant: ["hero-left-split", "hero-right-split"],
      background: "moss-green",
      class: "text-brand-primary-darker text-5xl max-md:text-left",
    },
  ],
});

const imageVariants = cva("relative m-auto w-full md:h-full", {
  variants: {
    variant: {
      background: "w-full",
      "showcase-large": "",
      "showcase-medium": "md:w-10/12",
      slider: "w-full",
      "hero-left-split": "",
      "hero-right-split": "",
      values: "",
      grid: "",
      about: "",
    },
    image: {
      background: "",
      "showcase-large": "w-full",
      "showcase-medium": "w-full",
      slider: "",
      "hero-left-split": "h-full",
      "hero-right-split": "h-full",
      values: "",
      grid: "",
      about: "",
    },
  },
});

function ImageCard({
  image,
  mobileImage,
  variant,
}: {
  image?: Entry<TypeImageSkeleton, "WITHOUT_UNRESOLVABLE_LINKS">;
  mobileImage?: Entry<TypeImageSkeleton, "WITHOUT_UNRESOLVABLE_LINKS">;
  variant: ExtractSymbol<TypeHeroFields["variant"]>;
}) {
  if (!image) return null;

  return (
    <div className={cn(imageVariants({ variant }))}>
      <Image
        src={`https:${image?.fields.image?.fields.file?.url}`}
        alt={image?.fields.image?.fields.description ?? ""}
        height={600}
        width={
          variant === "hero-left-split" || variant === "hero-right-split"
            ? 600
            : 1280
        } // Controls render quality
        className={cn(
          "object-cover",
          imageVariants({ image: variant }),
          mobileImage ? "hidden md:block" : "",
        )}
      />
      {mobileImage && (
        <Image
          src={`https:${mobileImage?.fields.image?.fields.file?.url}`}
          alt={mobileImage?.fields.image?.fields.description ?? ""}
          height={600}
          width={600}
          className={cn(
            "object-cover",
            imageVariants({ image: variant }),
            mobileImage ? "block md:hidden" : "",
          )}
        />
      )}
    </div>
  );
}

function TextCard({
  fields,
  variant,
  textPosition,
  segmentFlow,
  isDesignProfileQuizEnabled,
}: {
  fields: TypeHero<"WITHOUT_UNRESOLVABLE_LINKS">["fields"];
  variant: ExtractSymbol<TypeHeroFields["variant"]>;
  textPosition: "center" | "left";
  segmentFlow: SegmentFlows;
  isDesignProfileQuizEnabled?: boolean;
}) {
  const listItemCount = fields.listItemCollection?.fields.listItems?.length;
  const ctaUrl = isDesignProfileQuizEnabled
    ? (fields.cta?.fields.challengerURL ?? fields.cta?.fields.url)
    : fields.cta?.fields.url;

  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center",
        variant === "hero-left-split" && fields.background !== "moss-green"
          ? "order-last"
          : "",
        variant === "hero-right-split" && fields.background !== "moss-green"
          ? "order-last md:order-first"
          : "",
      )}
    >
      <div
        className={cn(
          textPosition === "left" ? "text-left" : "",
          textPosition === "left" && fields.background === "moss-green"
            ? "lg:w-5/6"
            : "",
          variant === "showcase-large" ? "w-full" : "",
        )}
      >
        {fields.eyebrow && (
          <span className="font-display text-lg uppercase tracking-tight opacity-50">
            {fields.eyebrow}
          </span>
        )}
        <div
          className={cn(
            "px-0 pb-8 md:pb-0",
            variant === "showcase-large"
              ? "justify-left grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8"
              : "",
          )}
        >
          {fields.title &&
            !fields.displayOptions?.includes("suppress-title") && (
              <H3
                className={cn(
                  textVariants({ variant, background: fields.background }),
                )}
              >
                {fields.title}
              </H3>
            )}
          {fields.subtitle && (
            <H4 className="text-brand-primary-darker text-2xl max-md:px-4 md:text-3xl">
              {fields.subtitle}
            </H4>
          )}
        </div>
        {fields.description && (
          <ReactMarkdown
            components={{
              p: ({ children }) => (
                <P
                  className={cn(
                    textVariants({ descriptionVariant: variant }),
                    "whitespace-pre-line",
                  )}
                >
                  {children}
                </P>
              ),
            }}
          >
            {fields.description}
          </ReactMarkdown>
        )}
        {fields.listItemCollection && (
          <div className="mt-4">
            <ListItemCollection
              listItemCollection={fields.listItemCollection}
              listItemsClassName={
                listItemCount && listItemCount > 3
                  ? "grid-cols-2"
                  : "grid-cols-1"
              }
              listItemHeadingClassName="text-base text-brand-primary-regular"
              listItemIconClassName="text-brand-greens-light h-[16px] w-[16px]"
              listItemIconWrapperClassName="w-7 h-7 px-1.5 pb-1 pt-1"
              iconSize="sm"
            />
          </div>
        )}
        {fields.cta && (
          <div className="px-0 pb-2 md:pt-6">
            <Button
              className="mx-2 w-full"
              asChild
              tracking={{
                button_content: fields.cta?.fields.buttonText,
                flow_name: segmentFlow,
                button_name: "Hero CTA Button",
              }}
            >
              <Link
                className="m-0 hidden whitespace-nowrap font-medium max-[429px]:py-3 max-[390px]:px-3 max-[365px]:whitespace-normal max-[365px]:px-6 max-[365px]:leading-3 min-[360px]:inline-flex"
                href={ctaUrl}
              >
                {fields.cta?.fields.buttonText}
              </Link>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
